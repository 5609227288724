import React from "react"
import { graphql } from "gatsby"

const ProjectTemplate = ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    const { title } = frontmatter
    return (
        <div className="ALLO">
            <h1 className="a">{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
    )
}

export default ProjectTemplate

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
            }
        }
    }
`
